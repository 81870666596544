<template>
  <div>
    <div class="head">基本资料</div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="登陆账户">
        <div>
          {{ !form.email||form.email=='null' ? "未设置邮箱" : form.email
          }}<i class="el-icon-edit-outline"></i>
          <el-link type="primary" :underline="false">
            <router-link to="email"> 修改登录邮箱</router-link>
          </el-link>
        </div>
        <div>
          {{ form.phone }}<i class="el-icon-edit-outline"></i>
          <el-link type="primary" :underline="false">
            <router-link to="phone"> 修改手机号码</router-link>
          </el-link>
        </div>
      </el-form-item>
      <i class="neccesary_key"></i>
      <el-form-item label="昵称" :required="true">
        <el-input
          @change="inputName()"
          v-model="form.loginName"
          minlength="2"
          maxlength="8"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名" :required="true">
        <el-input
          @change="inputName()"
          v-model="form.name"
          minlength="2"
          maxlength="8"
        ></el-input
        >2至8个字符
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- 居住地暂时先隐藏 -->
      <el-form-item v-if="false" label="居住地">
        <el-cascader
          v-model="form.address"
          :options="options"
          @change="handleChange"
          placeholder="请选择省份"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="" class="label">
        <span
          ><span class="neccesary_key">* </span>请选择你擅长的领域<span
            class="check-tip"
            >(单选)</span
          ></span
        >

        <el-checkbox-group
          v-model="field"
          :max="1"
          ref="group"
          :class="{ changeinput: true }"
        >
          <el-checkbox
            :data-value="value"
            class="selBtn"
            v-for="(value, index) in sorts"
            :key="index"
            :label="value"
          >
            {{ value }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="个人签名">
        <el-input
          @change="inputintro()"
          type="textarea"
          v-model="form.intro"
          maxlength="30"
          placeholder="不超过30字"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancelChange()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
import { QUERY, UPDATE, DELETE, INSERT, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      centersED: "", //中间转换
      form: {
        id: "",
        email: "",
        phone: "",
        birthday: "",
        address: "",
        intro: "",
        img: "",
        name: "",
      },
      field: [],
      sorts: [], // 擅长领域分类
      tempField: [],
      options: [
        // 省区
        {
          value: "省区",
          label: "省区",
          children: [
            {
              value: "河北省",
              label: "河北省",
            },
            {
              value: "山西省",
              label: "山西省",
            },
            {
              value: "辽宁省",
              label: "辽宁省",
            },
            {
              value: "吉林省",
              label: "吉林省",
            },
            {
              value: "黑龙江省",
              label: "黑龙江省",
            },
            {
              value: "江苏省",
              label: "江苏省",
            },
            {
              value: "浙江省",
              label: "浙江省",
            },
            {
              value: "安徽省",
              label: "安徽省",
            },
            {
              value: "福建省",
              label: "福建省",
            },
            {
              value: "江西省",
              label: "江西省",
            },
            {
              value: "山东省",
              label: "山东省",
            },
            {
              value: "河南省",
              label: "河南省",
            },
            {
              value: "湖北省",
              label: "湖北省",
            },
            {
              value: "湖南省",
              label: "湖南省",
            },
            {
              value: "广东省",
              label: "广东省",
            },
            {
              value: "海南省",
              label: "海南省",
            },
            {
              value: "四川省",
              label: "省",
            },
            {
              value: "贵州省",
              label: "贵州省",
            },
            {
              value: "云南省",
              label: "云南省",
            },
            {
              value: "陕西省",
              label: "陕西省",
            },
            {
              value: "甘肃省",
              label: "甘肃省",
            },
            {
              value: "青海省",
              label: "青海省",
            },
            {
              value: "台湾省",
              label: "台湾省",
            },
          ],
        },
        {
          value: "自治区",
          label: "自治区",
          children: [
            {
              value: "内蒙古自治区",
              label: "内蒙古自治区",
            },
            {
              value: "广西壮族自治区",
              label: "广西壮族自治区",
            },
            {
              value: "新疆维吾尔族自治区",
              label: "新疆维吾尔族自治区",
            },
            {
              value: "西藏自治区",
              label: "西藏自治区",
            },
            {
              value: "宁夏回族自治区",
              label: "宁夏回族自治区",
            },
          ],
        },
        {
          value: "直辖市",
          label: "直辖市",
          children: [
            {
              value: "北京市",
              label: "北京市",
            },
            {
              value: "天津市",
              label: "天津市",
            },
            {
              value: "上海市",
              label: "上海市",
            },
            {
              value: "重庆市",
              label: "重庆市",
            },
          ],
        },
        {
          value: "特别行政区",
          label: "特别行政区",
          children: [
            {
              value: "香港特别行政区",
              label: "香港特别行政区",
            },
            {
              value: "澳门特别行政区",
              label: "澳门特别行政区",
            },
          ],
        },
      ],
      isChanged: true, // 基本资料如没有修改则不能点击保存
      initInfo: {}, // 初始化用户信息备份
    };
  },
  created() {
    this.init();
  },
  amount() {},
  methods: {
    ///敏感词过滤
    async centerd(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centersED = response.data.message.filterContent;
          console.log("赋值成功：" + this.centersED);
        })
        .catch(function (error) {
          console.log("错误" + error);
        });
    },
    ///用户名
    async inputName() {
      await this.centerd(this.form.name); //敏感词
      this.form.name = this.centersED; //赋值
    },
    ///个性签名
    async inputintro() {
      await this.centerd(this.form.intro); //敏感词
      this.form.intro = this.centersED; //赋值
    },
    async init() {
      let info = await QUERYED(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}}) {id    name    loginName    intro  email  img    phone    birthday  address   industry}'
      );
      this.form = info.data.PCUsers[0];
      if (info.data.PCUsers[0].address != null) {
        this.form.address = info.data.PCUsers[0].address;
      }
      if (info.data.PCUsers[0].phone === null) {
        this.form.phone = "";
      }
      if (info.data.PCUsers[0].email === null) {
        this.form.email = "";
      }
      if (info.data.PCUsers[0].birthday === "") {
        this.form.birthday = "";
      }
      if (info.data.PCUsers[0].industry !== "") {
        this.field.push(info.data.PCUsers[0].industry);
      }

      // 初始化领域
      let info2 = await QUERYED("post", "", "Type {id    type}");
      console.log("info2", info2);
      if (info2.data.Type.length != 0) {
        this.sorts = [];
        for (let i = 0; i < info2.data.Type.length; i++) {
          this.sorts.push(info2.data.Type[i].type);
        }
      }
    },

    async onSubmit() {
      if (this.$store.state.userinfo.name != this.form.name) {
        // 根据用户输入的昵称 查找数据库 该昵称是否未被注册
        let search = await QUERYED(
          "post",
          "",
          'PCUsers(where:{name:{_eq:"' + this.form.name + '"}}) {id}'
        );
        // console.log("search_affected_rows_loginName",search);
        // console.log("输入的昵 查找数据库 该昵称是否未被注册: ",search.data.PCUsers.length>0)
        if (search.data.PCUsers.length > 0) {
          this.$message.warning("该用户名已被占用!");
          return false;
        }
        // 验证昵称是否被占用
      }
      if (this.field.length === 0) {
        this.$message.warning("请选择个人领域!");
        return;
      }
      let data = await UPDATE(
        "POST",
        "",
        'update_User(_set:{name:"' +
          this.form.name +
          '",intro: "' +
          this.form.intro +
          '", email: ' +
          (this.form.email?('"'+this.form.email+'"'):null) +
          ', phone: "' +
          this.form.phone +
          '", birthday: "' +
          this.form.birthday +
          '",address:"' +
          this.form.address +
          '",industry:"' +
          this.field +
          '"}, where: {id: {_eq:' +
          this.form.id +
          "}}) {  affected_rows }"
      );
      console.log(data,456);

      if (data.data.update_User.affected_rows > 0) {
        this.$store.state.userinfo.email = this.form.email;
        this.$store.state.userinfo.phone = this.form.phone;
        this.$store.state.userinfo.name = this.form.name;
        this.$store.state.userinfo.birthday = this.form.birthday;
        this.$store.state.userinfo.industry = this.field;
        this.$store.state.userinfo.intro = this.form.intro;
        this.$message({
          message: "修改成功",
          type: "success",
          duration: "1000",
        });
      } else {
        this.$message({
          message: "修改失败",
          type: "warning",
          duration: "1000",
        });
      }
    },
    cancelChange() {
      this.init(); // 取消更改
    },
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>
<style scoped>
.head {
  border-bottom: 1px solid #b5b5b5;
  line-height: 50px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
.label {
  margin-left: -50px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 50%;
  margin-right: 10px;
}
/* 加入红星 */
.neccesary_key {
  content: "*";
  color: #ff2d52;
  font-size: 14px;
}
/* .neccesary_key .el-form-item__label:before{
  content: "*";
  color: #ff2d52;
  font-size: 14px;
} */

/* 最多3个 */
.check-tip {
  color: #dcdfe6;
  font-size: 10px;
}
/* 复选框改变样式 */
.changeinput >>> .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  -webkit-transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.changeinput >>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
}
</style>
